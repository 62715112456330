<!--
 * @Description: 企业获取信息--接口
 * @Author: huacong
 * @Date: 2021-04-15 09:26:15
 * @LastEditTime: 2021-07-30 17:42:15
 * @LastEditors: huacong
-->
<template>
  <div></div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "comInfo",
  computed: {
    ...mapState(["comId"]),
  },
  methods: {
    ...mapMutations(["setcomRealeInfo", "setcomStaffInfo", "setcomReale"]),
    getRealeInfo() {
      // 企业认证信息
      if (this.comId == 0) {
        this.$http.post("company/index/getcompanyinfo").then((res) => {
          if (res.data.resultCode === 1) {
            this.setcomRealeInfo({
              comRealeInfo: res.data.resultData,
            });
          }
        });
      } else {
        this.$http
          .post("company/index/getcompanyinfo", { u_company: this.comId })
          .then((res) => {
            if (res.data.resultCode === 1) {
              this.setcomRealeInfo({
                comRealeInfo: res.data.resultData,
              });
            }
          });
      }
      // 企业员工信息
      this.$http.post("company/user/getpersonal").then((res) => {
        if (res.data.resultCode === 1) {
          this.setcomStaffInfo({
            comStaffInfo: res.data.resultData,
          });
        }
      });
    },
  },
  mounted() {
    this.getRealeInfo();
  },
};
</script>
