<!--
 * @Description: 企业用户首页职位推荐
 * @Author: huacong
 * @Date: 2021-07-13 17:10:13
 * @LastEditTime: 2021-07-15 09:28:39
 * @LastEditors: huacong
-->
<template>
  <div>
    <el-tabs v-model="recommendResume">
      <el-tab-pane label="推荐简历" name="first">
        <el-table :data="partData" class="partTimeTable tableHover">
          <el-table-column width="100px">
            <template slot-scope="scope">
              <div
                style="padding-left:10px"
                @click="gorecomme(scope.row, scope.row.uid)"
              >
                <span style="font-weight:700" class="fontHover">
                  {{ scope.row.tal_name.slice(0, 1) }}工
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="360px">
            <template slot-scope="scope">
              <div
                class="text-center"
                @click="gorecomme(scope.row, scope.row.uid)"
              >
                <span> {{ scope.row.certificate }}-{{ scope.row.major }} </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="500px">
            <template slot-scope="scope">
              <div
                class="partType"
                @click="gorecomme(scope.row, scope.row.uid)"
              >
                <span>{{ scope.row.zs_price }}万</span>
                <span>{{ scope.row.register }}</span>
                <span
                  >{{ scope.row.region_province }}-{{
                    scope.row.region_city
                  }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <div
                class="text-right"
                @click="gorecomme(scope.row, scope.row.uid)"
                style="padding-right:10px"
              >
                <span style="color: #2573F1">{{ scope.row.create_time }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            background
            @size-change="sizeChange1"
            @current-change="currentChange1"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableTotal"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchInfo: {},
      partData: [],
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      recommendResume: "first",
    };
  },
  methods: {
    // 获取人员兼职列表
    getParttimeList(page, limit) {
      if (page == undefined || limit == undefined) {
        this.searchInfo.page = 1;
        this.searchInfo.limit = 10;
      } else {
        this.searchInfo.page = this.currentPage;
        this.searchInfo.limit = this.pagesize;
      }
      this.$http.post("index/resume/parresume", this.searchInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.partData = res.data.resultData.list;
          this.tableTotal = parseInt(res.data.resultData.count);
        }
      });
    },
    sizeChange1(e) {
      this.pagesize = e;
      this.currentPage = 1;
      this.getParttimeList(this.currentPage, this.pagesize);
    },
    currentChange1(e) {
      this.currentPage = e;
      this.getParttimeList(e, this.pagesize);
    },
    // 跳转建筑企业详情--和猎头荐才通用
    gorecomme(item, uid) {
      let routeUrl = this.$router.resolve({
        name: "recommeInfo",
        query: {
          id: item.id,
          uid: uid,
          cer: item.certificate,
          major: item.major,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  mounted() {
    this.getParttimeList();
  },
};
</script>
<style scoped>
.searchBox {
  margin: 0px 100px;
}
.searchInput {
  width: calc(100% - 136px);
}
/deep/.searchBox .el-input__inner {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
/deep/.searchBox .el-input-group__append {
  background-color: #0070fa;
  border-color: #0070fa;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: #fff;
  cursor: pointer;
}
.el-input-group__append img {
  width: 20px;
}
.conditionBox {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 24px;
}
/deep/.conditionForm .el-form-item {
  margin-bottom: 0;
  padding: 7px 20px;
  border-bottom: 1px solid #e8e8e8;
}
/deep/.conditionForm .el-form-item__label {
  color: #333;
  font-size: 17px;
  font-weight: bold;
}
.proviceBox {
  height: 40px;
  overflow: hidden;
  position: relative;
}
.foldbtn {
  position: absolute;
  right: -10px;
  top: 0px;
}
.conditionForm .selbtn {
  color: #333;
  font-size: 13px;
}
.el-button--text.color999 {
  color: #999;
}
.selcerBtn,
.selmajorBtn,
.selcityBtn,
.selproviceBtn {
  padding-left: 7px;
  padding-right: 7px;
}
.selcerBtn.active,
.selmajorBtn.active,
.selcityBtn.active,
.selproviceBtn.active {
  background: #2573f1;
  border-radius: 4px;
  color: #fff;
}
.partType span:not(:last-child) {
  margin-right: 40px;
}
.partType span:first-child {
  font-weight: bold;
  font-size: 14px;
  color: #f00;
  display: inline-block;
  width: 90px;
}
.partType span:nth-child(2) {
  display: inline-block;
  width: 70px;
}
.partType span:not(:first-child) {
  color: #999;
}
/deep/ .partTimeTable .el-table__header-wrapper {
  height: 0;
}
</style>
<style>
.tableHover .el-table__body tr:hover > td {
  background-color: #d9e6ff !important;
}
</style>
