<template>
  <!-- 企业首页 -->
  <div class="contentBox comBg">
    <section>
      <div class="resumeTitle clearfix boxShadow">
        <div class="clearfix">
          <!-- border-b-l -->
          <div class="block talentAvd">
            <el-image
              style="width: 100px; height: 100px"
              :src="circleUrl"
            ></el-image>
            <span class="demonstration">
              <el-button
                plain
                round
                size="mini"
                @click="goPath('/comPage/personal')"
                v-prevent-repeat-click
              >
                编辑信息
              </el-button>
            </span>
          </div>
          <!-- 信息 -->
          <div class="talentInfo">
            <div class="contact">
              <span class="name">{{ comStaffInfo.u_name }}</span>
              <span>
                <img :src="telIcon" alt="" />
                <span>{{ comStaffInfo.u_phone }}</span>
              </span>
              <span>
                <img :src="emailIcon" alt="" />
                <span v-if="comStaffInfo.eamail != ''">
                  {{ comStaffInfo.eamail }}
                </span>
              </span>
            </div>
            <div class="baseInfo">
              <span>
                您是
                <b style="color:#f5be3f">
                  {{
                    vipInfo.u_grade_text == ""
                      ? "普通用户"
                      : vipInfo.u_grade_text
                  }}</b
                >
                <span style="padding: 0">
                  {{
                    "，您还有" +
                      vipInfo.u_surplus_push +
                      "次发布次数、" +
                      vipInfo.u_surplus_refresh +
                      "次刷新次数、" +
                      vipInfo.u_surplus_down +
                      "次下载次数"
                  }}
                </span>
              </span>
            </div>
          </div>
          <div class="down">
            <el-button type="primary" class="radius-25" @click="postJobBtn">
              发布信息
            </el-button>
          </div>
        </div>
        <!-- <div class="mini-menu border-b-l">
          <el-row>
            <el-col class="menu-5">未付款的订单 {{ 0 }}</el-col>
            <el-col class="menu-5">未使用的会员 {{ 0 }}</el-col>
            <el-col class="menu-5">展示中的广告 {{ 0 }}</el-col>
            <el-col class="menu-5">黄金展位信息 {{ 0 }}</el-col>
            <el-col class="menu-5">收到简历数 {{ 0 }}</el-col>
          </el-row>
        </div> -->
      </div>
      <div style="margin-top: 20px;">
        <cwRecommend></cwRecommend>
      </div>
      <comInfo></comInfo>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import comInfo from "@/view/comPage/comInfo.vue";
import cwRecommend from "@/components/cwRecommend/cwRecommend.vue";
export default {
  data() {
    return {
      circleUrl: require("@/assets/icon/avatar.png"),
      telIcon: require("@/assets/icon/tel.png"),
      emailIcon: require("@/assets/icon/email.png"),
      vipInfo: {},
    };
  },
  components: {
    comInfo,
    cwRecommend,
  },
  computed: {
    ...mapState(["userInfo", "comRealeInfo", "comStaffInfo", "comId"]),
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  watch: {
    comStaffInfo() {
      this.circleUrl = this.comStaffInfo.u_portrait_url;
    },
  },
  methods: {
    ...mapMutations([]),
    // 发布
    postJobBtn() {
      this.$http
        .post("index/recharge/recharge", { id: this.userInfo.uid })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.vipInfo = res.data.resultData;
            if (this.vipInfo.u_surplus_push < 1) {
              this.$message.warning(
                "您的发布次数已用完，您可以前往会员服务购买会员！"
              );
            } else {
              if (this.comId != 0 || this.userInfo.u_company != 0) {
                this.$router.push("postType");
              } else {
                this.$message.success(
                  "您还未认证企业，现在前往完善认证企业信息！"
                );
                setTimeout(() => {
                  this.$router.push("comrealSel");
                }, 1200);
              }
            }
          }
        });
    },
    getVipInfo() {
      this.$http
        .post("index/recharge/recharge", { id: this.userInfo.uid })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.vipInfo = res.data.resultData;
          }
        });
    },
    goPath(src) {
      this.$router.push({ path: src });
    },
  },
  mounted() {
    this.getVipInfo();
  },
};
</script>
<style scoped>
.resumeTitle {
  /* border-bottom: 1px solid #e8e8e8; */
  background: #fff;
}
.talentIndex {
  padding-top: 24px;
}
.demonstration {
  margin-top: 10px;
}
.talentAvd {
  display: block;
  float: left;
}
.talentAvd.block {
  padding: 20px 20px 0px;
}
.talentInfo {
  display: block;
  float: left;
  width: calc(100% - 330px);
  padding: 28px 0px;
}
.talentInfo .name {
  font-size: 16px;
  display: inline-block;
}
.talentInfo .baseInfo span {
  display: inline-block;
  padding: 4px 16px;
}
.talentInfo .baseInfo span:first-child {
  padding-left: 0;
}
.contact > span {
  padding: 10px 0;
  display: inline-block;
}
.contact span img {
  position: relative;
  top: 2px;
  padding-right: 6px;
}
.contact > span {
  padding-right: 20px;
}
.down {
  text-align: right;
  padding-right: 20px;
  padding-top: 40px;
}
.mini-menu {
  width: 100%;
}
.menu-5 {
  width: 20%;
  text-align: center;
  padding: 20px 0;
  cursor: pointer;
}
</style>
